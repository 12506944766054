<template>
  <div>
    
    
    <Explaination/>     
        
    
    <services />
    <CustomerStatements />
    <get-in-touch />
    
  </div>
</template>

<script>
  export default {
    name: 'CoreView',

    components: {
      GetInTouch: () => import('@/components/GetInTouch'),
      Services: () => import('@/components/Services'),
      CustomerStatements: () => import('@/components/CustomerStatements'),
      Explaination: () => import('@/components/Explaination')
     
    },
  }
</script>
